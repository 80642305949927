import { parseRawHtmlDoc } from '~/utils/dom-parser'

export default (path, prefix) => ({
  query: '',

  init() {
    this.$refs.search.focus()
  },

  load(group) {
    htmx.ajax('GET', path, {
      swap: 'none',
      values: { scope: prefix, group: group, query: this.query },
      handler: (_, { xhr: { responseText } }) => {
        document
          .querySelector('#relations')
          ?.replaceWith(
            parseRawHtmlDoc(responseText).querySelector('#relations'),
          )
      },
    })
  },
})
