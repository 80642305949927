import { getCSRFToken } from '~/utils/security'
import { flashFailure } from '~/utils/flash'

export default (path) => ({
  openPreditor() {
    htmx.ajax('POST', path, {
      headers: { 'X-CSRF-Token': getCSRFToken() },
      swap: 'none',
      values: {
        amount: this.quantity,
        extra_ids: this.selectedOptions,
      },
      handler: (_, { xhr: { responseText } }) => {
        const response = JSON.parse(responseText)
        if (response.redirect_to) location.href = response.redirect_to
        else flashFailure(response.message || 'oeps, er ging iets fout!')
      },
    })
  },
})
