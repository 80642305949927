export default () => ({
  show: 'plano',
  showResult: false,
  cover: false,
  weight: 0,
  coverWeight: 0,
  cardboardBackWeight: 400,
  cassette: 0,
  width: 0,
  height: 0,
  amount: 0,
  sheets: 0,
  result: 0,
  pieceG: '',
  pieceKg: '',
  totalG: '',
  totalKg: '',

  reset() {
    this.showResult = false
    this.cover = false
    this.weight = 0
    this.coverWeight = 0
    this.cassette = 0
    this.width = 0
    this.height = 0
    this.amount = 0
    this.sheets = 0
    this.result = 0
    this.pieceG = ''
    this.pieceKg = ''
    this.totalG = ''
    this.totalKg = ''
  },

  convertToKg(result, amount) {
    return (result * amount) / 1000
  },

  // Set width and height based on selected format
  setWidthAndHeight() {
    this.width = parseFloat(
      this.$el.querySelector('option:checked').dataset.width
    )
    this.height = parseFloat(
      this.$el.querySelector('option:checked').dataset.height
    )
  },

  calcPlano() {
    this.result = ((this.width * this.height) / 1000) * (this.weight / 1000)
    this.pieceG = Math.round(this.result)
    this.pieceKg = this.convertToKg(this.result, 1).toFixed(3)
    this.totalG = Math.round(this.result) * this.amount
    this.totalKg = this.convertToKg(this.result, this.amount).toFixed(2)
    this.showResult = true
  },

  calcBrochures() {
    this.resultCover = 0

    if (this.cover) {
      this.resultCover =
        ((this.width * this.height) / 1000) * (this.coverWeight / 1000) * 2
    }

    this.result = ((this.width * this.height) / 1000) * (this.weight / 1000)
    this.result = (this.result * this.sheets) / 2
    this.pieceG = Math.round(this.result + this.resultCover)
    this.pieceKg = this.convertToKg(this.result + this.resultCover, 1).toFixed(
      3
    )
    this.totalG = Math.round(this.result + this.resultCover) * this.amount
    this.totalKg = this.convertToKg(
      this.result + this.resultCover,
      this.amount
    ).toFixed(2)

    this.showResult = true
  },

  calcPads() {
    this.result = ((this.width * this.height) / 1000) * (this.weight / 1000)
    this.result = this.result * this.sheets
    this.cardboardBack =
      ((this.width * this.height) / 1000) * (this.cardboardBackWeight / 1000)
    this.pieceG = Math.round(this.result + this.cardboardBack)
    this.pieceKg = this.convertToKg(
      this.result + this.cardboardBack,
      1
    ).toFixed(3)
    this.totalG = Math.round(this.result + this.cardboardBack) * this.amount
    this.totalKg = this.convertToKg(
      this.result + this.cardboardBack,
      this.amount
    ).toFixed(2)

    this.showResult = true
  },

  calcRollups() {
    this.result =
      ((this.width * this.height) / 1000) * (this.weight / 1000) + this.cassette
    this.pieceG = Math.round(this.result)
    this.pieceKg = this.convertToKg(this.result, 1).toFixed(3)
    this.totalG = Math.round(this.result) * this.amount
    this.totalKg = this.convertToKg(this.result, this.amount).toFixed(2)

    this.showResult = true
  },
})
