export default (singular, minW, maxW, minH, maxH) => ({
  updateDimensions() {
    if (singular) this.$refs.height.value = this.$refs.width.value

    if (this.validDimensions()) {
      this.$el.closest('form').requestSubmit()
      this.$refs.width.disabled = true
      this.$refs.height.disabled = true
    }
  },

  validDimensions() {
    const [w, h] = [
      parseInt(this.$refs.width.value),
      parseInt(this.$refs.height.value),
    ]
    return (
      (w >= minW && w <= maxW && h >= minH && h <= maxH) ||
      (w >= minH && w <= maxH && h >= minW && h <= maxW)
    )
  },
})
