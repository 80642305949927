import { gsap } from 'gsap'

export default () => ({
  scrollTo(ref) {
    this.$nextTick(() =>
      gsap.to(window, {
        duration: 0.3,
        scrollTo: { y: this.$refs[ref], offsetY: 50 },
        ease: 'power2',
      })
    )
  },
})
