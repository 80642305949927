/**
 * Adds a success flash message to the DOM.
 *
 * @param message - Flash message.
 */
export function flashSuccess(message) {
  flashMessage(message, 'notice')
}

/**
 * Adds an error flash message to the DOM.
 *
 * @param message - Flash message.
 */
export function flashFailure(message) {
  flashMessage(message, 'error')
}

/**
 * Adds a info flash message to the DOM.
 *
 * @param message - Flash message.
 */
export function flashInfo(message) {
  flashMessage(message, 'info')
}

/**
 * Adds an flash message to the DOM.
 *
 * @param message - Flash message.
 * @param type - Flash type.
 */
export function flashMessage(message, type) {
  let hide
  hideAllFlashMessages()
  const flash = document.createElement('flash-component')
  flash.classList.add('flash', `flash--${type}`)
  hide = () => {
    flash.classList.add('flash--hidden')
    document.removeEventListener('click', hide)
  }
  document.addEventListener('click', hide)
  flash.innerHTML = message
  document.body.append(flash)
}

/**
 * Hides all existing flash messages.
 */
export function hideAllFlashMessages() {
  document.querySelectorAll('.flash').forEach((flash) => {
    flash.classList.add('flash--replaced')
    setTimeout(() => flash?.remove(), 500)
  })
}
