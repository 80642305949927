import { getCSRFToken } from '~/utils/security'
import { flashInfo, flashFailure } from '~/utils/flash.js'

export default (path, satisfied) => ({
  xhr: null,
  progress: 0,
  touched: false,
  busy: false,
  satisfied,

  init() {
    this.$refs.input && (this.$refs.input.value = '')
    this.progress = 0
    this.xhr = new XMLHttpRequest()
  },

  busy() {
    return this.progress > 0 && this.progress < 100
  },

  start() {
    if (this.validate()) this.upload()
  },

  validate() {
    if (this.$refs.input.files.length == 0) {
      flashFailure('Please select at least one file')
      return false
    }

    return true
  },

  upload() {
    this.xhr.open('POST', path, true)
    this.xhr.setRequestHeader('X-CSRF-Token', getCSRFToken())
    this.xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) this.progress = (e.loaded / e.total) * 100
    }
    this.xhr.onload = () => {
      if (this.xhr.status === 200)
        this.$root.replaceWith(parseHtml(this.xhr.responseText))
      else flashFailure(`Upload failed. Status: ${this.xhr.status}`)
    }
    this.xhr.send(gather(this.$refs.input.files))
  },

  cancel() {
    this.xhr.abort()
    this.init()
    flashInfo('Job upload canceled')
  },
})

function gather(files) {
  const formData = new FormData()
  for (let i = 0; i < files.length; i++) formData.append('files[]', files[i])
  return formData
}

function parseHtml(html) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')
  return doc.body.firstChild
}
