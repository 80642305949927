export default (options) => ({
  openSides: false,
  openCuts: false,
  openRatios: false,
  openQualities: false,
  openPhotoQualities: false,
  application: '',
  ratio: '',
  cut: '',
  print: '',
  quality: '',

  setApplication(application) {
    this.application = application
    switch (this.application) {
      case 'graphic':
        this.openRatios = false
        this.ratio = ''
        this.openPhotoQualities = false
        this.openCuts = true
        break
      case 'photo':
        this.openCuts = false
        this.cut = ''
        this.openQualities = false
        this.openRatios = true
        break
    }
  },

  setRatio(ratio) {
    this.ratio = ratio

    if (this.$refs.photoQualities) {
      this.openPhotoQualities = true
    }

    if (!this.$refs.photoQualities) {
      // console.log(
      location.assign(
        `/shop/art-photo/${options.photoMaterial}/${this.ratio}/quality/${options.photoSpecs}`
      )
    }
  },

  setPhotoQuality(quality) {
    this.quality = quality
    switch (this.quality) {
      case 'white':
        // console.log(
        location.assign(
          `/shop/art-photo/${options.photoMaterial}-white/${this.ratio}/quality/${options.photoSpecs}-wit-full-cmyk-recto`
        )
        break
      case 'natural':
        // console.log(
        location.assign(
          `/shop/art-photo/${options.photoMaterial}-natural/${this.ratio}/quality/${options.photoSpecs}-natural-recto`
        )
        break
    }
  },

  setQuality(quality) {
    this.quality = quality
    location.assign(
      //console.log(
      `/shop/borden-panelen/${options.boardMaterial}/${this.cut}/quality/${
        options.boardSpecs
      }${
        this.quality === '' || this.quality === undefined
          ? ''
          : '-' + this.quality
      }`
    )
  },

  setCut(cut) {
    this.print = ''
    this.quality = ''

    this.cut = cut

    if (this.cut === 'lifesizer') {
      // console.log(
      location.assign(`/lifesizers`)
      return
    }

    if (this.$refs.qualities) {
      this.openQualities = true
    }

    if (this.$refs.sides) {
      this.openSides = true
    }

    if (!this.$refs.sides && !this.$refs.qualities) {
      // console.log(
      location.assign(
        `/shop/borden-panelen/${options.boardMaterial}/${this.cut}/quality/${options.boardSpecs}-recto`
      )
    }
  },

  setPrint(print) {
    this.print = print
    switch (this.print) {
      case 'recto':
        // console.log(
        location.assign(
          `/shop/borden-panelen/${options.boardMaterial}/${this.cut}/quality/${
            options.boardSpecs
          }${
            this.quality === '' || this.quality === undefined
              ? ''
              : '-' + this.quality
          }-recto`
        )
        break
      case 'recto-verso':
        // console.log(
        location.assign(
          `/shop/borden-panelen/${options.boardMaterial}/${this.cut}/quality/${
            options.boardSpecs
          }${
            this.quality === '' || this.quality === undefined
              ? ''
              : '-' + this.quality
          }-rv`
        )
        break
    }
  },
})
