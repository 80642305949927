export default (groupedDate) => ({
  useEconomy() {
    this.setValues(groupedDate)
  },

  useQuick() {
    this.setValues()
  },

  setValues(date) {
    Array.from(
      this.$root.querySelectorAll('[data-behavior="job-date"]'),
    ).forEach((select) => (select.value = date || select.options[0].value))
  },
})
