import './browser_component.css'
import { getCSRFToken } from '~/utils/security'

export default (path, selector) => ({
  chooseJob(sourceJobId) {
    this.updateJob(sourceJobId)
    this.$dispatch('modal:confirm')
  },

  updateJob(sourceJobId) {
    htmx.ajax('POST', path, {
      headers: { 'X-CSRF-Token': getCSRFToken() },
      values: { source_job_id: sourceJobId },
      swap: 'outerHTML',
      select: selector,
      target: selector,
    })
  },
})
