export default (quantity) => ({
  oldValue: `${quantity}`,
  readonly: false,

  tail() {
    const length = this.$el.value.length
    this.$el.setSelectionRange(length, length)
  },

  sanitize() {
    this.quantity = this.$el.value.replace(/\D/g, '')
  },

  submit() {
    if (this.$el.value === '' || this.$el.value === this.oldValue) return

    this.$el.form.requestSubmit()
    this.readonly = true
  },
})
