import './pages_input_component.css'

export default (value, min, max, step) => ({
  value: value * 1,

  increment() {
    this.value = Math.min(this.value * 1 + step, max)
  },

  decrement() {
    this.value = Math.max(this.value * 1 - step, min)
  },

  confirm(path) {
    console.log(this.value)
    htmx.ajax('GET', path.replace(':pages', this.value), 'body')
  },
})
