import { flashSuccess, flashFailure } from '~/utils/flash'
import { getCSRFToken } from '~/utils/security'

export default (path, purchaseId) => ({
  code: '',
  status: '',

  applyDiscount() {
    htmx.ajax('POST', path, {
      headers: { 'X-CSRF-Token': getCSRFToken() },
      values: {
        code: this.code,
        purchase_id: purchaseId || '',
      },
      swap: 'none',
      handler: (_, { xhr: { responseText } }) => {
        // const { type, message } = JSON.parse(responseText)

        // if (type === 'error') return flashFailure(message)

        // this.updatePrice()
        // flashSuccess(message)
        // this.status = type
        // this.$refs.message.innerHTML = message
        console.log(responseText)
      },
    })
  },
})
