export default (checked) => ({
  checked,
  disabled: false,

  init() {
    this.$watch(
      'checked',
      (checked) => {
        this.$refs.input.value = checked ? 1 : 0
        this.disabled = true
        this.$refs.input.form.requestSubmit()
      },
      10,
    )
  },
})
