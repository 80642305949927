import { flashSuccess, flashFailure } from '~/utils/flash'
import { getCSRFToken } from '~/utils/security'

export default (path) => ({
  toggleActive() {
    htmx.ajax('PUT', path, {
      headers: { 'X-CSRF-Token': getCSRFToken() },
      swap: 'none',
      values: {
        'page[active]': this.$el.checked,
        source: 'toggle',
      },
      handler: (_, { xhr: { responseText } }) => {
        const { status, message } = JSON.parse(responseText)
        status === 'success' ? flashSuccess(message) : flashFailure(message)
      },
    })
  },
})
