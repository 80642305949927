import { getCSRFToken } from '~/utils/security'
import { flashFailure } from '~/utils/flash'

export default (path, jobId) => ({
  copy: false,

  openPreditor() {
    htmx.ajax('POST', path, {
      headers: { 'X-CSRF-Token': getCSRFToken() },
      swap: 'none',
      values: {
        copy_design: this.copy ? '1' : '0',
        job_id: jobId,
      },
      handler: (_, { xhr: { responseText } }) => {
        const response = JSON.parse(responseText)
        if (response.redirect_to) location.href = response.redirect_to
        else flashFailure(response.message || 'oeps, er ging iets fout!')
      },
    })
  },
})
