import Alpine from 'alpinejs'

const productDetailTab = document.addEventListener('alpine:init', () => {
  Alpine.store('productDetailTab', {
    current: Alpine.$persist(true).as('currentProductDetailTab'),
    toggleProperties() {
      this.current = 'properties'
    },
    toggleOptions() {
      this.current = 'options'
    },
  })
})

export default productDetailTab
