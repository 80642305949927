import './progress_bar_component.css'

export default () => ({})

const WAIT = 1000

document.addEventListener('htmx:configRequest', () => {
  const progressBar = document.getElementById('progress_bar')
  progressBar.style.width = '0'
  progressBar.style.display = 'block'

  const start = Date.now
  const updateProgressBar = () => {
    const elapsed = Date.now - start
    if (elapsed < WAIT) {
      progressBar.style.width = (elapsed / WAIT) * 100 + '%'
      requestAnimationFrame(updateProgressBar)
    } else {
      setTimeout(() => {
        progressBar.style.width = '100%'
      }, 250)
    }
  }
  updateProgressBar()
})

document.addEventListener('htmx:afterRequest', () => {
  const progressBar = document.getElementById('progress_bar')
  progressBar.style.width = '100%'
  setTimeout(() => {
    progressBar.style.display = 'none'
    progressBar.style.width = '0'
  }, 100)
})
