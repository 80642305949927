import './active_search_component.css'

export default (query) => ({
  query,

  selectFirstResult() {
    this.$refs.results.querySelector('li:first-child a').focus()
  },

  selectNextSibling() {
    const nextSibling = this.$el.closest('li').nextElementSibling
    if (nextSibling) nextSibling.querySelector('a').focus()
  },

  selectPreviousSibling() {
    const previousSibling = this.$el.closest('li').previousElementSibling
    if (previousSibling) previousSibling.querySelector('a').focus()
    else this.$refs.input.focus()
  },
})
