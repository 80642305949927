export default (hideThumbnails, slides) => ({
  hideThumbnails,
  slides,
  current: 0,

  showNext() {
    this.current++
    if (this.current > this.slides.length - 1) {
      this.current = 0
    }
  },

  showPrev() {
    this.current--
    if (current < 0) {
      current = this.slides.length - 1
    }
  },
})
