import { createWidget } from '@typeform/embed'
import '@typeform/embed/build/css/widget.css'

export default (formId) => ({
  init() {
    createWidget(formId, {
      container: this.$el,
      autoResize: true,
    })
  },
})
