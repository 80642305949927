// Load CSS entrypoint
import '~/application.css'

// HTMX
import * as htmx from 'htmx.org'
window.htmx = htmx

// Idiomorph
import { Idiomorph } from 'idiomorph/dist/idiomorph.esm.js'

// Set up Alpine
import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import ajax from '@imacrayon/alpine-ajax'
import anchor from '@alpinejs/anchor'
import focus from '@alpinejs/focus'
import persist from '@alpinejs/persist'
import { registerAlpineExtensions } from '~/utils/alpine-extensions'
import { getCSRFToken } from '~/utils/security.js'

// Alpine components
registerAlpineExtensions(
  'data',
  import.meta.glob('~/**/components/*.js', { eager: true }),
)
registerAlpineExtensions(
  'data',
  import.meta.glob('~/../components/**/*_component.js', { eager: true }),
  /components\/(.+)_component\.js$/,
)

// Alpine directives
registerAlpineExtensions(
  'directive',
  import.meta.glob('~/**/directives/*.js', { eager: true }),
)

// Alpine magics
registerAlpineExtensions(
  'magic',
  import.meta.glob('~/**/magics/*.js', { eager: true }),
)

// Alpine stores
registerAlpineExtensions(
  'store',
  import.meta.glob('~/**/stores/*.js', { eager: true }),
)

// Alpine plugins
Alpine.plugin(collapse)
Alpine.plugin(focus)
Alpine.plugin(anchor)
Alpine.plugin(persist)
Alpine.plugin(
  ajax.configure({
    headers: { 'X-CSRF-Token': getCSRFToken() },
  }),
)

// Start Alpine
Alpine.start()

// Allow 404 and 422 error codes with htmx
document.body.addEventListener('htmx:beforeSwap', (event) => {
  if ([404, 422].includes(event.detail.xhr.status)) {
    event.detail.shouldSwap = true
    event.detail.isError = false
  }
})

// GSAP import
import { gsap } from 'gsap'

// Import GSAP plugins:
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

// Register GSAP plugins
gsap.registerPlugin(ScrollToPlugin)
