export default (title, jobId) => ({
  selector: `#task_${jobId}_options`,

  openModal() {
    this.$store.modal.inline(title, `${this.selector}_template`, {
      size: 'medium',
      height: 'large',
      confirm: () => this.updateOptions(),
      cancel: true,
    })
  },

  updateOptions() {
    this.$refs.input.value = document.querySelector(this.selector).value
    this.$refs.input.form.requestSubmit()
  },
})
