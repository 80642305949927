export default () => ({
  selectedOptions: [],
  problematicOptions: [],

  init() {
    this.selectOptions()
  },

  selectOptions() {
    const checked = this.$root.querySelectorAll('input[type=radio]:checked')
    this.selectedOptions = Array.from(checked)
      .map((radio) => parseInt(radio.value))
      .sort((a, b) => a - b)
  },
})
