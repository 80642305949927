export default () => ({
  count: 0,

  init() {
    this.updateCount()
  },

  updateCount() {
    this.count = this.$refs.options.querySelectorAll(
      'input[type=radio]:checked'
    ).length
  },
})
