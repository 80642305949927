/**
 * Parses raw HTML string into a DOM object.
 *
 * @param {string} rawHtml
 * @returns {HTMLElement}
 */
export const parseRawHtmlDoc = (rawHtml) =>
  new DOMParser().parseFromString(rawHtml, 'text/html').body

/**
 * Parses raw HTML string into a DOM object and selects a part of it.
 *
 * @param {string} rawHtml
 * @param {string} selector
 * @returns {HTMLElement}
 */
export const parseRawHtmlPart = (rawHtml, selector) =>
  parseRawHtmlDoc(rawHtml).querySelector(selector)
