export default (options) => ({
  cut: '',
  laminateLayer: '',
  printMethod: '',
  showLaminateLayerChoice: false,
  showPrintMethodChoice: false,

  setCut(cut) {
    this.printMethod = ''
    this.laminateLayer = ''
    this.showLaminateLayerChoice = false
    this.showPrintMethodChoice = false
    this.cut = cut

    if (this.cut == 'pictogrammen') {
      window.location.assign(
        `/shop/stickers-zelfklevend/${options.foilMaterial}/pictogram`
      )
      return   
    }

    if (this.$refs.printMethodChoice) {
      this.showPrintMethodChoice = true
      return
    }

    if (this.$refs.laminateLayerChoice) {
      this.showLaminateLayerChoice = true
      return
    }

    if (!this.$refs.printMethodChoice && !this.$refs.laminateLayerChoice) {
      window.location.assign(
        `/shop/stickers-zelfklevend/${options.foilMaterial}/${this.cut}/quality/${options.foilSpecs}`
      )
    }
  },

  setPrintMethod(printMethod) {
    this.printMethod = printMethod

    if (this.$refs.laminateLayerChoice) {
      this.showLaminateLayerChoice = true
      return
    }

    if (!this.$refs.laminateLayerChoice) {
      window.location.assign(
        `/shop/stickers-zelfklevend/${options.foilMaterial}/${this.cut}/quality/${options.foilSpecs}-${this.printMethod}`
      )
      return
    }
  },

  setLaminateLayer(laminateLayer) {
    this.laminateLayer = laminateLayer

    if (options.normalize) {
      this.laminateLayer = this.normalizedLaminateLayerUriPart(laminateLayer)
    }

    if (!this.$refs.printMethodChoice) {
      if (this.laminateLayer == 'not-laminated') {
        window.location.assign(
          `/shop/stickers-zelfklevend/${options.foilMaterial}/${this.cut}/quality/${options.foilSpecs}`
          )
          return
      }
      
      window.location.assign(
      `/shop/stickers-zelfklevend/${options.foilMaterial}/${this.cut}/quality/${options.foilSpecs}-${this.laminateLayer}`
      )
      return
    }

    if (this.laminateLayer == 'not-laminated') {
      window.location.assign(
        `/shop/stickers-zelfklevend/${options.foilMaterial}/${this.cut}/quality/${options.foilSpecs}-${this.printMethod}`
      )
      return
    }

    window.location.assign(
      `/shop/stickers-zelfklevend/${options.foilMaterial}/${this.cut}/quality/${options.foilSpecs}-${this.printMethod}-${this.laminateLayer}`
    )
  },

  normalizedLaminateLayerUriPart(part) {
    switch (part) {
      case 'plus-gloss':
        return 'plus-clear'
      default:
        return part
    }
  },
})
