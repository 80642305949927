export default () => ({
  init() {
    this.$root.querySelectorAll('input, select, textarea').forEach((input) => {
      input.dataset.erroneousValue = input.value

      input.addEventListener('input', () => this.toggle(input))
      input.addEventListener('change', () => this.toggle(input))
    })
  },

  toggle(input) {
    this.$root.classList.toggle(
      'field-with-errors',
      input.value == input.dataset.erroneousValue,
    )
  },
})
