export default (modalTitle, quantity, description) => ({
  edit() {
    this._showModal()
    this.$nextTick(() => this._presetInputs())
  },

  _showModal() {
    this.$store.modal.inline(modalTitle, '#quantity_modal_template', {
      cancel: true,
      confirm: () => {
        this.$refs.quantity.value = getModalEl('input').value
        this.$refs.description.value = getModalEl('description').value
        this.$refs.quantity.form.requestSubmit()
      },
    })
  },

  _presetInputs() {
    getModalEl('input').value = quantity
    getModalEl('description').value = description
  },
})

const getModalEl = (name) => document.querySelector(`#quantity_modal_${name}`)
