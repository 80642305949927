export default () => ({
  files: [],

  showFiles() {
    this.files = Array.from(this.$refs.input.files)
  },

  removeFile(file) {
    this.files = this.files.filter((f) => f !== file)
    this.updateInput()
  },

  updateInput() {
    const dataTransfer = new DataTransfer()
    this.files.forEach((file) => dataTransfer.items.add(file))
    this.$refs.input.files = dataTransfer.files
  },
})
