import './custom_format_component.css'

export default (
  singular,
  { minW, maxW, minH, maxH },
  { tooSmall, tooLarge },
) => ({
  minL: maxW > maxH ? minW : minH,
  maxL: maxW > maxH ? maxW : maxH,
  minS: maxW > maxH ? minH : minW,
  maxS: maxW > maxH ? maxH : maxW,
  widthError: null,
  heightError: null,

  update() {
    if (singular) this.$refs.height.value = this.$refs.width.value
  },

  validate() {
    const isValid = this.valid()
    if (!isValid) this.reportErrors()

    return isValid
  },

  submit(form) {
    if (!this.validate()) return

    this.$store.modal.confirm()
    setTimeout(() => document.querySelector(form).requestSubmit(), 10)
  },

  visit(url) {
    if (!this.validate()) return

    const href = this.customFormatUrl(url)
    this.$store.modal.confirm()
    setTimeout(() => htmx.ajax('GET', href, 'body'), 10)
  },

  sanitize() {
    this.widthError = null
    this.heightError = null
    this.$el.value = this.$el.value.replace(/\D/g, '')
  },

  neutralize() {
    const side = this.$el.name

    if (!this[side + 'Error']) return

    this[side + 'Error'] = null
    this.$el.value = this.$el.dataset.initial
  },

  valid() {
    const [w, h] = this.values()
    return (
      (w >= minW && w <= maxW && h >= minH && h <= maxH) ||
      (w >= minH && w <= maxH && h >= minW && h <= maxW)
    )
  },

  reportErrors() {
    const [w, h] = this.values()
    const [uS, uL, vS, vL] = this.limits(w, h)

    if (w < uS) this.widthError = interpolate(tooSmall, uS)
    else if (w > uL) this.widthError = interpolate(tooLarge, uL)
    if (h < vS) this.heightError = interpolate(tooSmall, vS)
    else if (h > vL) this.heightError = interpolate(tooLarge, vL)
  },

  limits(w, h) {
    return w > h
      ? [this.minL, this.maxL, this.minS, this.maxS]
      : [this.minS, this.maxS, this.minL, this.maxL]
  },

  values() {
    return [
      parseInt(this.$refs.width.value) || 0,
      parseInt(this.$refs.height.value) || 0,
    ]
  },

  customFormatUrl(baseUrl) {
    return baseUrl.replace(
      /CUSTOM_X_Y/,
      `custom_${this.$refs.width.value}x${this.$refs.height.value}`,
    )
  },
})

function interpolate(message, value) {
  return message.replace(/%{size}/g, value)
}
