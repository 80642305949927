import { flashSuccess } from '~/utils/flash'
import { getCSRFToken } from '~/utils/security'

export default (path, active) => ({
  active,

  toggle() {
    htmx.ajax('PUT', path, {
      headers: { 'X-CSRF-Token': getCSRFToken() },
      swap: 'none',
      handler: (_, { xhr: { responseText } }) => {
        const { message } = JSON.parse(responseText)
        flashSuccess(message)
        this.active = !this.active
      },
    })
  },
})
