import { createPopover } from '@typeform/embed'
import '@typeform/embed/build/css/popover.css'

export default (formId) => ({
  init() {
    const { toggle } = createPopover(formId, {
      autoResize: true,
    })
    this.open = toggle
  },
})
